export default {
  id: 'c94e4b3c6f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page reminder-page" data-name=reminder><div class=navbar><div class=navbar-inner><div class=left><a class="link back"><i class="icon icon-back"></i></a></div></div></div><div class=page-content><div class=reminder-page-top><div class=reminder-title><h1>Erinnerung</h1></div></div><div class=reminder-content><h2>Wähle einen Zeitpunkt für deine Erinnerung</h2><form><label>Uhrzeit</label><input id=reminder-time type=time> <label>Datum</label><input id=reminder-date type=date></form><button id=set-reminder-button class="button button-raised reminder-back-link">Erinnere mich!</button></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};