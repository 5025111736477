export default {
  id: '72c7b6dcd3',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=alltasks-page class="page background-image background-mountain" data-name=alltasks><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link back"><i class="icon material-icons">navigate_before</i></a></div></div></div><!-- Toolbar--><div class=main-toolbar></div><!-- FAB --><div class=main-fab></div><!-- Scrollable page content--><div class=page-content><div class=page-content-top><h1 data-dic-key=alltasks-title class=page-title></h1><h2 data-dic-key=alltasks-subtitle class=page-subtitle></h2></div><div class=page-content-main><div class=alltasks-task-cards><div class=filter-cards><a class=popover-open href=# data-popover=#alltasks-popover-tasks-sort><label data-dic-key=sort-after></label> <i class="icon material-icons">navigate_next</i></a></div><div id=alltasks-task-cards-container></div><div id=alltasks-tasks-placeholder class="block placeholder-container"><label class=placeholder data-dic-key=alltasks-tasks-placeholder></label></div></div></div></div><div id=alltasks-popover-tasks-sort class=popover><div class=popover-inner><div class=list><ul id=alltasks-sort-list></ul></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};