export default {
  id: 'a3ed10b63d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page tasks-page background-image background-mountain" data-name=task><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link back"><i class="icon material-icons">navigate_before</i></a></div></div></div><!-- Toolbar --><div class=main-toolbar></div><!-- Floating Action Button --><div class=main-fab></div><!-- Scrollable page content--><div id=task-page-content class=page-content><div class=page-content-top><h1 id=task-title class=page-title></h1><h2 id=task-subtitle class=page-subtitle></h2></div><div id=task-content class=page-content-main></div><div id=task-bottom class=page-content-bottom><div class="button-list shadowed"></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};