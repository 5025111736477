import $$ from 'dom7';
import app from '../../app';
import json from '../../download/onboarding.json';

const onboardingView = {
  init: function () {
    this.initSlideListener();
    this.initSlideContent();

    // Remove option to proceeed without login if web-version
    if (!app.device.cordova) {
      $$('.register-column').remove();
      //$$('.row.skip-row').remove();
      $$('#anmelden-button-onboarding').attr('href', '/login/login/');
    }
  },

  initSlideListener: function () {
    app.swiper
      .get('.swiper-container')
      .on('slideChangeTransitionStart', function () {
        let slider = $$('.swiper-wrapper')[0];
        for (
          let slideIndex = 0;
          slideIndex < slider.children.length;
          slideIndex++
        ) {
          if (
            slider.children[slideIndex].classList.contains(
              'swiper-slide-active',
            )
          ) {
            $$('.background' + (slideIndex + 1)).css('opacity', 1);
          } else {
            $$('.background' + (slideIndex + 1)).css('opacity', 0);
          }
        }
      });
  },
  initSlideContent: function () {
    for (let [i, str] of json.slides.entries()) {
      $$('#onboarding-slide-' + String(i + 1)).html(str);
    }
  },
};

export default onboardingView;
