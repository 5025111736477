export default {
  id: '7a7dc43c3c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=noteeditor><!-- Navbar --><div class=navbar></div><!-- Toolbar --><div class=main-toolbar></div><!-- Scrollable page content--><div id=noteeditor-page-content class=page-content><div class=page-content-top><h1 id=noteeditor-title class=page-title></h1></div><div class=page-content-main><div id=keydate-steps-filter class=filter-cards><a class=popover-open href=# data-popover=#noteeditor-popover-steps><label id=noteeditor-step>Schritt</label> <i class="icon material-icons">navigate_next</i> </a><label id=noteeditor-created></label></div><div class=list><div class=list-group><ul><li><div id=noteeditor-input-content role=textbox contenteditable></div></li></ul></div></div></div></div><div id=noteeditor-popover-menu class=popover><div class=popover-inner><div class=list><ul><li><a id=noteeditor-popover-delete class="list-button item-link" href=#>Notiz löschen</a></li><li><a id=noteeditor-popover-export class="list-button item-link" href=#>Notiz exportieren</a></li><li><a id=noteeditor-popover-edit class="list-button item-link" href=#>Notiz bearbeiten</a></li></ul></div></div></div><div id=noteeditor-popover-steps class=popover><div class=popover-inner><div class=list><ul></ul></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};